import React from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./style";
import Lovepreneur from "../../images/logo_Lovepre-x-odoj.png";

const Logo = (props) => {
  const { classes } = props;

  let logo = Lovepreneur;

  return <img className={classes.mainLogo} src={logo} alt="" />;
};

export default withStyles(styles)(Logo);
