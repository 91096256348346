import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Paper, Typography, Grid, Card } from "@material-ui/core";
import useStyles from "./styles";
import {
  firestore,
  FieldValue,
  functions,
  useFirebase,
} from "../../../components/FirebaseProvider";
import AppPageLoading from "../../../components/AppPageLoading";
import Logo from "../../../components/Logo";
import StoreIcon from "@material-ui/icons/Store";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import StudentsIcon from "@material-ui/icons/People";
import VideoIcon from "@material-ui/icons/Movie";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { useDocument } from "react-firebase-hooks/firestore";
import ListPencairan from './listPencairan';
import RekTujuan from './rekBankTujuan';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useData } from "../../../components/DataProvider";
import { currency } from '../../../utils/formatter';

function Afiliasi() {
  const classes = useStyles();
  const { user } = useFirebase();
  const { pencairanList } = useData();
  const [isLoading, setIsloading] = useState(false);
  const [refInfo, setRefinfo] = useState({
    userRef: "",
    total_komisi: 0,
    count_komisi: 0,
    groupRef: "",
    total_komisi_group: 0,
    count_komisi_group: 0
  });
  const [refGroupInfo, setRefGroupInfo] = useState({
    groupRef: "",
    total_komisi_group: 0,
    count_komisi_group: 0
  });

  let env = "DEV";
  if (process.env.REACT_APP_ENV === "PROD") {
    env = "PROD";
  }

  const [filter, setFilter] = useState('pencairan');
  const userDoc = firestore.doc(`user/${user.uid}`);
  const [snapshotUserDoc, userLoading] = useDocument(userDoc);
  const settingDoc = firestore.doc('settings/disbursement');
  const [snapshotSettingData, settingDataLoading] = useDocument(settingDoc);
  
  const handleChangeTab = (event, value) => {
        setFilter(value);
    }
  const [tabs] = useState([
        {
            label: "Pencairan", value: "pencairan"
        },
        {
            label: "Rek. Bank Tujuan", value: "rek_bank_tujuan"
        },
    ])

  const [prodInfo, setProdinfo] = useState({});
  const [settingDetail, setSettingDetail] = useState({});

  const resKomisi = async (id) => {
    setIsloading(true);
    const prodDoc = await firestore.collection(`produk`).where("produk_id","==", "2").get();
    setProdinfo(prodDoc.docs[0].data());

    const getTotalKomisi = functions.httpsCallable("statistic-getTotalKomisi");
    const res = await getTotalKomisi({ user_id: id });
    setRefinfo({
        userRef: id,
        total_komisi: res.data[0]?.total_komisi,
        count_komisi: res.data[0]?.count_komisi,
        total_pencairan_processing: res.data[0]?.total_pencairan_processing,
        total_pencairan_success: res.data[0]?.total_pencairan_success,
        total_fee_pencairan: res.data[0]?.total_fee_pencairan
      })
    setIsloading(false);

  }

  const resKomisiGroup = async (refgroupid) => {
    setIsloading(true);

    const getTotalKomisiGroup = functions.httpsCallable("statistic-getTotalKomisiGroup");
    const res = await getTotalKomisiGroup({ refgroup: refgroupid });
    setRefGroupInfo({
        refGroup: refgroupid,
        total_komisi_group: res.data[0]?.total_komisiGroup,
        count_komisi_group: res.data[0]?.count_komisiGroup
      })
    setIsloading(false);

  }

    useEffect(() => {
    if (snapshotUserDoc) {
      resKomisi(snapshotUserDoc.id)
      if (snapshotUserDoc?.data()?.isGroupLeader) {
        resKomisiGroup(snapshotUserDoc?.data()?.refgroup)
      }
    }
  }, [snapshotUserDoc, pencairanList]);

  useEffect(()=>{
    if (snapshotSettingData) {
      setSettingDetail(snapshotSettingData.data());
    }
  },[snapshotSettingData])

  return(
      <>
      <Helmet>
        <title>Nobar - Lovepreneur The Movie</title>
      </Helmet>
      <Grid>
          <Paper className={classes.paper}>
<div style={{ display: "flex", justifyItems: "center" }}>
              <Logo />
            </div>

      {isLoading && !userLoading ? <AppPageLoading /> : <div>
      

      <p>ID unik afiliasi kamu : {refInfo.userRef}</p>
      {/* <p>Link affiliasi (halaman utama) : https://{env === 'PROD' ? "movie" : "movie"}.lovepreneur.co.id/?ref={refInfo.userRef}&produk_id=1</p> */}
      <p>Link affiliasi : https://{env === 'PROD' ? "odoj" : "odoj-dev"}.lovepreneur.co.id/daftar/ref={refInfo.userRef}&produk_id=2</p>
      <p>Komisi setiap penjualan: {currency(prodInfo?.komisi_affiliate * prodInfo?.harga_produk)} ( {prodInfo?.komisi_affiliate * 100}% )</p>
      
      <p>Jumlah komisi : {refInfo.total_komisi ? currency(refInfo.total_komisi) : "0"} ( {refInfo.count_komisi} transaksi )</p>
      {refGroupInfo.refGroup ? <p>Jumlah Komisi Group : {refGroupInfo.total_komisi_group ? currency(refGroupInfo.total_komisi_group) : "0"} ( {refGroupInfo.count_komisi_group } transaksi )</p> : ""}
      <p>Pencairan (proses) : {refInfo.total_pencairan_processing ? currency(refInfo.total_pencairan_processing) : "0"} </p>
      <p>Pencairan (sukses) : {refInfo.total_pencairan_success ? currency(refInfo.total_pencairan_success) : "0"} / Fee pencairan : {refInfo.total_fee_pencairan ? currency(refInfo.total_fee_pencairan) : "0"} / Diterima : {refInfo.total_fee_pencairan ? currency(refInfo.total_pencairan_success - refInfo.total_fee_pencairan) : "0"}</p>
      
        </div>}

          </Paper>
      <Tabs indicatorColor="primary"
                    className={classes.tabWrap}
                    textColor="primary"
                    value={filter}
                    variant="fullWidth"
                    onChange={handleChangeTab}>
                    {tabs.map(tab => <Tab key={tab.value} label={tab.label} value={tab.value} />)}

      </Tabs>
          <div>
                    <ListPencairan value={filter} dataKomisi={pencairanList} responOmset={refInfo} responOmsetGroup={refGroupInfo} minPencairan={settingDetail.min_amount}/>
                    <RekTujuan value={filter} user_id={refInfo.userRef} />
          </div>
      </Grid>
      </>
  )
  
}

export default Afiliasi;
