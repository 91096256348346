import React, { useState } from "react";
import clsx from "clsx";

import Drawer from "@material-ui/core/Drawer";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import SettingsInputComponentIcon from "@material-ui/icons/SettingsInputComponent";

import Container from "@material-ui/core/Container";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import SignOutIcon from "@material-ui/icons/ExitToApp";
import DashboardIcon from "@material-ui/icons/Dashboard";
import StoreIcon from "@material-ui/icons/Store";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import StudentsIcon from "@material-ui/icons/People";
import CategoriesIcon from "@material-ui/icons/Category";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import Logo from "../../components/Logo";

import useStyles from "./styles";

import { Switch, Route, useRouteMatch, useHistory } from "react-router-dom";

//import Courses from './courses';
import Dashboard from "./dashboard";
//import Students from './students';
//import Enrolment from './enrolment';
import Afiliasi from "./afiliasi";
import Gathering from "./gathering";
import Seminar from "./seminar"

import { auth } from "../../components/FirebaseProvider";
//import Categories from './categories';

export default function Private() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const matchDashboard = useRouteMatch({
    path: "/",
    exact: true,
  });
  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleLogout = (e) => {
    auth.signOut();
  };

  const history = useHistory();

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleSignOut = (e) => {
    if (window.confirm("Apakah anda yakin ingin keluar?")) auth.signOut();
  };

  return (
    <div className={classes.root}>
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            <Switch>
              {/* <Route path="/courses" children="Courses" />
                            <Route path="/enrolment" children="Enrolment" />
                            <Route path="/categories" children="Categories" />
                            <Route path="/students" children="Students" /> */}
              {/* <Route path="/" children="Dashboard" /> */}
              <Route path="/nobar" children="Dashboard" />
              <Route path="/afiliasi" children="Afiliasi" />
              <Route path="/gathering" children="Gathering" />
              <Route path="/seminar" children="Seminar" />
            </Switch>
          </Typography>
          {/* <IconButton onClick={handleSignOut} color="inherit">
            <SignOutIcon />
          </IconButton> */}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <Logo />
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List className={classes.iconColor}>
          <ListItem
            button
            selected={matchDashboard ? true : false}
            onClick={() => {
              history.push("/nobar");
            }}
          >
            <ListItemIcon>
              <DashboardIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Akses" />
          </ListItem>

          <ListItem
            button
            selected={matchDashboard ? true : false}
            onClick={() => {
              history.push("/afiliasi");
            }}
          >
            <ListItemIcon>
              <StudentsIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Afiliasi" />
          </ListItem>

          {/* <ListItem
            button
            selected={matchDashboard ? true : false}
            onClick={() => {
              history.push("/gathering");
            }}
          >
            <ListItemIcon>
              <CategoriesIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Gathering" />
          </ListItem>*/}

          <ListItem
            button
            selected={matchDashboard ? true : false}
            onClick={() => {
              history.push("/seminar");
            }}
          >
            <ListItemIcon>
              <StoreIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Seminar" />
          </ListItem> 

          <ListItem
            button
            onClick={handleClickOpen}
            className={classes.listItem}
            selected={openDialog}
          >
            <ListItemIcon>
              <ExitToAppIcon color="error" />
            </ListItemIcon>
            <ListItemText className={classes.liText} primary="Logout" />
          </ListItem>
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Switch>
            {/* <Route path="/courses" component={Courses} />
                        <Route path="/enrolment" component={Enrolment} />
                        <Route path="/students" component={Students} />
                        <Route path="/categories" component={Categories} /> */}
            <Route exact path="/" component={Dashboard} />
            <Route path="/nobar" component={Dashboard} />
            <Route path="/afiliasi" component={Afiliasi} />
            <Route path="/gathering" component={Gathering} />
            <Route path="/seminar" component={Seminar} />
          </Switch>
        </Container>
      </main>

      {/* Dialog Box Logout */}

      <Dialog
        open={openDialog}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        className={classes.dialog}
      >
        <DialogContent>
          <Grid container justify="center" alignItems="center">
            <Grid item>
              <ReportProblemOutlinedIcon fontSize="large" color="secondary" />
            </Grid>
          </Grid>
          <DialogTitle id="alert-dialog-slide-title">
            <Typography className={classes.centerText}>
              Apakah Anda Yakin?
            </Typography>
          </DialogTitle>
        </DialogContent>
        <DialogActions>
          <ButtonGroup fullWidth variant="text">
            <Button onClick={handleClose} color="primary">
              Tidak
            </Button>
            <Button onClick={handleLogout}>Logout</Button>
          </ButtonGroup>
        </DialogActions>
      </Dialog>
    </div>
  );
}
